.voting {
  border: 2px black solid;
  text-decoration: none;
  display: block;
  padding: 1rem;
}

.question {
  margin-top: 1rem;
  color: #323232;
}

.question__description {
  color: #727272;
}

.question__options {
  text-decoration: none;
}

.question__options__check {
  align-items: center;
  gap: 1rem;
  font-size: 16px;
}

.question__options__check:active,
.question__options__check:hover,
.question__options__check:focus {
  border: solid 1px #a2bd30;
}
