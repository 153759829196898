.Home .awssld__controls__arrow-left:before,
.Home .awssld__controls__arrow-left:after,
.Home .awssld__controls__arrow-right:before,
.Home .awssld__controls__arrow-right:after,
.Home .awssld__timer {
	background-color: #a2bd30;
}

.awssld__content>img,
.awssld__content>video {
	filter: brightness(0.7);
}

.awssld__content .slider-item {
	position: absolute;
	top: 17%;
	left: 9%;
	right: 9%;
}

.awssld__content .slider-item .slider-title,
.awssld__content .slider-item .slider-content {
	font-family: 'Roboto Slab';
	color: white;
    padding-bottom: 16px;
}

.awssld__content .slider-item .slider-title {
	font-weight: 600;
	font-size: 2em;
}

.awssld__content .slider-item .slider-content {
	font-weight: 500;
	font-size: 1em;
}

@media (min-width: 768px) {
	.awssld__content .slider-item .slider-title {
		font-size: 5em;
	}

	.awssld__content .slider-item .slider-content {
		font-size: 1.5em;
	}
}



