@font-face {
    font-family: "NexaBold";
    src: local("NexaBold"),
     url("../../assets/fonts/nexa/NexaBold.otf") format("truetype");
    font-weight: bold;
}

.text-handwrite {
  font-family: 'Itim', cursive;
    font-size: 25px;
}
/* ==============================
   Base styles
   ============================== */
html {
    color: #222;
    font-size: 1em;
    line-height: 1.4;
}
::-moz-selection {
    background: #a2bd30;
    text-shadow: none;
  color: #fff;
}
::selection {
    background: #a2bd30;
    text-shadow: none;
  color: #fff;
}
hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 0;
}
audio,
canvas,
iframe,
img,
svg,
video {
    vertical-align: middle;
}
fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}
a:focus{outline: 0;}
*:focus{outline:none;}
input,
textarea {
  box-shadow: none;
  width: 100%;
  height: 45px;
  padding: 0 15px;
  margin-bottom: 20px;
  border: 1px solid #e8e8e8;
  border-radius: unset;
  transition: 0.3s ease;
}
textarea {
  resize: vertical;
  height: 150px;
  padding: 15px;
}
input:focus,
textarea:focus {
  border-color: #a2bd30;
  box-shadow: none;
}
label.checkbox {
  font-size: 2rem;
  font-weight: bold;
  line-height: 1.1;
  display: grid;
  grid-template-columns: 1em auto;
  gap: 0.5em;
  margin-bottom: 20px;
}
input[type="checkbox"] {
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  margin: 5px 0 0 0;
  font: inherit;
  color: currentColor;
  width: 1.15em;
  height: 1.15em;
  border: 0.15em solid #a2bd30;
  border-radius: 0.15em;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
}
input[type="checkbox"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  transform: scale(0);
  transform-origin: bottom left;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
}
input[type="checkbox"]:checked::before {
  background-color: #a2bd30;
  transform: scale(1);
}
select {
  padding: 12px 15px;
  background: none;
  width: 100%;
  box-shadow: none;
  border: 1px solid #e8e8e8;
  margin-bottom: 20px;
}
select:focus {
  border-color: #a2bd30;
  box-shadow: none;
}
h1, h2, h3, h4, h5, h6 {
    color: #323232;
    font-family: "Roboto Slab", serif;
}
h2 {
    font-size: 40px;
}
h3 {
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
}
body {
    font-family: "Roboto", sans-serif;
}
p {
    font-size: 15px;
    color: #727272;
    font-weight: 400;
    line-height: 24px;
  text-align: justify;
}
ol, li {
  text-align: justify;
    color: #727272;
}
a {
    transition: 0.3s ease;
}
a:hover,
a:active,
a:focus {
    text-decoration: none;
}
form button {
  padding: 13px 40px;
  text-transform: uppercase;
  font-weight: 500;
  color: #fff;
  background: #a2bd30;
  border: 2px solid #a2bd30;
  transition: 0.3s ease;
}
form button:hover {
  background: none;
  color: #a2bd30;
}
form button:disabled {
  cursor: not-allowed;
  background-color: lightgrey;
  border: 2px solid #3c3d41;
  color: white;
}
.form-group {
  margin-bottom: 20px;
}
.img-thumbnail {
  border: none;
}
figure {
    margin: 0;
}

iframe{
  border: 0 none;
}

pre {
  white-space: pre-wrap;
}

.font-candela {
    padding-left: 15px;
    width: 120px;
    font-size: 25px;
    font-family: "NexaBold";
    color: #fff;
}

.el-btn-regular {
    font-size: 14px;
    color: #fff;
    background: #a2bd30;
    border: 2px solid #a2bd30;
    text-transform: uppercase;
    padding: 13px 25px;
    border-radius: 3px;
    font-weight: 500 !important;
    transition: 0.3s ease !important;
    display: inline-block;
}
.el-btn-regular:hover {
    color: #a2bd30;
    background: #fff;
    border-color: #fff;
}
.el-btn-medium {
    font-size: 15px;
    color: #fff;
    background: #a2bd30;
    border: 2px solid #a2bd30;
    text-transform: uppercase;
    padding: 20px 50px;
    border-radius: 3px;
    font-weight: 500 !important;
    transition: 0.3s ease !important;
}
.menu-area li {
        border-bottom: 1px dotted #ddd;
    padding: 10px 0 !important;
    text-transform: uppercase;
}
.el-btn-medium:hover {
    color: #a2bd30;
    background: #fff;
    border-color: #fff;
}
.slider-vplay-btn {
    display: inline-block;
    position: relative;
    top: 24px;
}
.slider-vplay-btn span {
    display: table-cell;
    vertical-align: middle;
}
.slider-vplay-btn span:first-child {
    width: 60px;
    height: 60px;
    background: #a2bd30;
    border-radius: 100px;
    color: #fff;
    font-size: 20px;
    text-align: center !important;
}
.slider-vplay-btn span:last-child {
    padding-left: 15px;
    width: 120px;
    font-size: 20px;
    font-family: "Roboto Slab", serif;
    color: #fff;
}
.rrss-inline {
    color: black;
    font-size: 20px;
    padding: 8px;
}
/* -------- Pre Loader -------- */
#preloader {
  position: fixed;
  top:0;
  left:0;
  right:0;
  bottom:0;
  background-color:#fefefe;
  z-index:99999;
}

#status {
  width:200px;
  height:200px;
  position:absolute;
  left:50%;
  top:50%;
  /* background-image:url('../images/preloader.gif'); */
  background-repeat:no-repeat;
  background-position:center;
  margin:-100px 0 0 -100px;
}
/* ==============================
  Home Main Version
================================ */
/* Header */

header .dropdown-menu {
  padding: 0;
}

div.header-top-dark {
    background: #262626;
    padding: 0px !important;

}
div.header-top-right a {
    color: #fff;
}
div.header-top-right a:hover {
    color: #a2bd30;
}
div.header-top-right {
    display: flex;
    justify-content: flex-end;
}
a.apply-btn,
button.apply-btn {
  padding: 13px 40px;
  background: #a2bd30;
  color: #fff;
  border-radius: 0;
  text-transform: uppercase;
  font-weight: 500;
  border: 2px solid #a2bd30;
  transition: 0.3s ease;
}
a.apply-btn:hover,
button.apply-btn:hover {
  border: 2px solid #a2bd30;
  background: #fff;
  color: #a2bd30;
}

.sticky-header {
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 11;
    background-color: white;
}

#main-nav {
  min-height: 100px;
}

/* Blog */

.blog-main .course08-carousel .carousel-item .course-single .course-image figure img {
    min-height: 335px;
  object-fit: contain;
}
.blog-main .course08-carousel .carousel-item .course-single:hover .course-info h3 a {
    color: #a2bd30;
}
.blog-main .course08-carousel .carousel-item .course-single .course-info {
    padding-right: 0;
}
.blog-main .course08-carousel .carousel-item .course-single .course-info h3 {
    line-height: 26px;
}
.blog-main .course08-carousel .carousel-item .course-single .course-info h3 a {
    color: inherit;
}
.blog-main .course08-carousel .carousel-item .course-single .course-info ul.author-info-main li {
    display: inline-block;
    float: left;
}
.blog-main .course08-carousel .carousel-item .course-single .course-info ul.author-info-main li img,
.blog-main .course08-carousel .carousel-item .course-single .course-info ul.author-info-main li span {
    display: inline-block;
}
.blog-main .course08-carousel .carousel-item .course-single .course-info ul.author-info-main li img {
    width: auto;
}
.blog-main .course08-carousel .carousel-item .course-single .course-info ul.author-info-main li span {
    padding-left: 10px;
}
.blog-main .course08-carousel .carousel-item .course-single .course-info ul.author-info-main li span a {
    color: inherit;
}
.blog-main .course08-carousel .carousel-item .course-single .course-info ul.author-info-main li:last-child {
    float: right;
    padding: 10px 0;
}
.blog-main .course08-carousel .carousel-item .course-single .course-info ul.blog-bottom li {
    color: #bababa;
    font-size: 14px;
}
.blog-main .course08-carousel .carousel-item .course-single .course-info ul.blog-bottom li:last-child {
    font-size: 14px;
    color: #bababa;
}
.blog-main .course08-carousel .carousel-item .course-single .course-info ul.blog-tags li {
    display: inline-block;
    padding-right: 5px;
}
.blog-main .course08-carousel .carousel-item .course-single .course-info ul.blog-tags li:last-child {
    padding-right: 0;
}
.blog-main .course08-carousel .carousel-item .course-single .course-info ul.blog-tags li a {
    color: #bababa;
}
.blog-main .section-header04 {
    margin-bottom: 10px;
}
.blog-main .section-header04 h2 {
    line-height: 30px;
}
.blog-main .owl-nav {
    top: -43px;
}


/* ===============================
  Home Page Version 01
================================ */
header .header-top {
    background: #3c3d41;
    padding: 15px 0px;
}
header .header-top .header-top-right {
    text-align: right;
    margin-bottom: 0;
    display: inline-block;
    margin-right: 0;
    color: #a2bd30;
}
header {
    padding: 0;
    transition: 0.3s ease;
}
header nav.navbar {
    margin-bottom: 0;
}
header nav.navbar .navbar-brand a.navbar-brand {
    padding: 23px 0;
    transition: 0.3s ease;
    height: auto;
}
header nav.navbar .navbar-brand a.navbar-brand img {
    margin-top: 5px;
}
header nav.navbar .navbar-collapse ul.navbar-nav {
    padding: 0;
}
header nav.navbar .navbar-collapse ul.navbar-nav li {
    margin-right: 40px;
}
header nav.navbar .navbar-collapse ul.navbar-nav li:last-child {
    margin-right: 0;
}
header nav.navbar .navbar-collapse ul.navbar-nav li a {
    color: #323232;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 14px;
    padding: 40px 0;
    display: inline-block;
    position: relative;
    text-transform: uppercase;
    transition: 0.3s ease;
}
header nav.navbar .navbar-collapse .navbar-nav a:hover,
header nav.navbar .navbar-collapse .navbar-nav a:focus,
header nav.navbar .navbar-collapse .navbar-nav a:active {
    background: none;
}
header nav.navbar .navbar-collapse .navbar-nav a:hover span {
    color: #a2bd30;
    border-bottom: 4px solid #a2bd30;
    border-top: 4px transparent;
}
header nav.navbar .navbar-collapse .navbar-nav .active a {
    color: #a2bd30;
    position: relative;
}
header nav.navbar .navbar-collapse .navbar-nav .active a:after {
    -webkit-transform: scale(1);
        -ms-transform: scale(1);
            transform: scale(1);
}
header nav.navbar .navbar-collapse .navbar-nav .dropdown a.dropdown-toggle:before {
    content: "\f107";
    font-family: FontAwesome;
    position: absolute;
    right: -15px;
    top: 50%;
    -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
            transform: translateY(-50%);
}
header nav.navbar .navbar-collapse .navbar-nav .dropdown-menu {
    display: block;
    visibility: hidden;
    opacity: 0;
    margin-top: -20px;
    transition: 0.3s ease;
    padding: 0;
    border-radius: 0;
    min-width: 215px;
    border-top: 3px solid #a2bd30;
    border-right: 0;
    border-bottom: 0;
    border-left: 0;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.13);
}
header nav.navbar .navbar-collapse ul.navbar-nav li ul.dropdown-menu li {
    padding: 0;
    margin-right: 0;
}
header nav.navbar .navbar-collapse ul.navbar-nav li ul.dropdown-menu li a {
    padding: 15px 20px;
    display: block;
    color: #696969;
}
header nav.navbar .navbar-collapse ul.navbar-nav li ul.dropdown-menu li a:hover {
    color: #a2bd30 !important;
}
header nav.navbar .navbar-collapse ul.navbar-nav li ul.dropdown-menu li a:after {
    content: none;
}
header nav.navbar .navbar-collapse ul.navbar-nav li ul.dropdown-menu li.dropdown-submenu {
    position: relative;
}
header nav.navbar .navbar-collapse ul.navbar-nav li ul.dropdown-menu li.dropdown-submenu a {
    display: inline-block;
    width: 100%;
}
header nav.navbar .navbar-collapse ul.navbar-nav li ul.dropdown-menu li.dropdown-submenu i {
    position: absolute;
    right: 0;
    padding: 15px;
}
header nav.navbar .navbar-collapse ul.navbar-nav li ul.dropdown-menu li.dropdown-submenu ul.dropdown-menu {
    display: block;
    visibility: hidden;
    opacity: 0;
    margin-top: -3px !important;
    left: 212px;
    top: 0;
}
header nav.navbar .navbar-collapse ul.navbar-nav li ul.dropdown-menu li.dropdown-submenu:hover ul.dropdown-menu {
    visibility: visible;
    opacity: 1;
    margin-top: 0;
}
header nav.navbar .navbar-collapse ul.navbar-nav li.dropdown {
    position: relative;
}
header nav.navbar .navbar-collapse ul.navbar-nav li.dropdown:hover ul.dropdown-menu {
    visibility: visible;
    opacity: 1;
    margin-top: 0;
}
header nav.navbar .navbar-collapse ul.navbar-nav li.dropdown i {
    display: inline-block;
    padding-left: 7px;
}
header nav.navbar .navbar-collapse ul.navbar-nav li:nth-child(6) ul.dropdown-menu li.dropdown-submenu ul.dropdown-menu,
header nav.navbar .navbar-collapse ul.navbar-nav li:nth-child(7) ul.dropdown-menu li.dropdown-submenu ul.dropdown-menu,
header nav.navbar .navbar-collapse ul.navbar-nav li:nth-child(8) ul.dropdown-menu li.dropdown-submenu ul.dropdown-menu,
header nav.navbar .navbar-collapse ul.navbar-nav li:nth-child(9) ul.dropdown-menu li.dropdown-submenu ul.dropdown-menu,
header nav.navbar .navbar-collapse ul.navbar-nav li:nth-child(10) ul.dropdown-menu li.dropdown-submenu ul.dropdown-menu,
header nav.navbar .navbar-collapse ul.navbar-nav li:nth-child(11) ul.dropdown-menu li.dropdown-submenu ul.dropdown-menu {
    left: -215px;
}
header nav.navbar .navbar-collapse ul.navbar-nav li:nth-child(9) ul.dropdown-menu,
header nav.navbar .navbar-collapse ul.navbar-nav li:nth-child(10) ul.dropdown-menu,
header nav.navbar .navbar-collapse ul.navbar-nav li:nth-child(11) ul.dropdown-menu {
    left: -140px;
}
header nav.navbar .navbar-collapse ul.navbar-nav li:nth-child(8) ul.dropdown-menu,
header nav.navbar .navbar-collapse ul.navbar-nav li:nth-child(9) ul.dropdown-menu,
header nav.navbar .navbar-collapse ul.navbar-nav li:nth-child(10) ul.dropdown-menu,
header nav.navbar .navbar-collapse ul.navbar-nav li:nth-child(11) ul.dropdown-menu {
    left: auto;
    right: 0;
}
header nav.navbar .navbar-collapse ul.navbar-nav li.mega-menu {
    position: inherit;
}
header nav.navbar .navbar-collapse ul.navbar-nav li.mega-menu i {
    display: inline-block;
    padding-left: 5px;
}
header nav.navbar .navbar-collapse ul.navbar-nav li.mega-menu ul.mega-menu-block {
    position: absolute;
    left: -100px;
    top: 110px;
    border-top: 3px solid #a2bd30;
    border-right: 0;
    border-bottom: 0;
    border-left: 0;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.13);
    width: 900px;
    z-index: 1;
    background: #fff;
    visibility: hidden;
    opacity: 0;
    transition: 0.3s ease;
}
header nav.navbar .navbar-collapse ul.navbar-nav li.mega-menu ul.mega-menu-block li {
    padding: 0;
}
header nav.navbar .navbar-collapse ul.navbar-nav li.mega-menu ul.mega-menu-block li .mega-menu-content {
    padding: 30px 0;
}
header nav.navbar .navbar-collapse ul.navbar-nav li.mega-menu ul.mega-menu-block li .mega-menu-content .mega-content-block {
    position: relative;
    padding: 0 30px;
    float: left;
    width: 25%;
}
header nav.navbar .navbar-collapse ul.navbar-nav li.mega-menu ul.mega-menu-block li .mega-menu-content .mega-content-block:before {
    position: absolute;
    content: "";
    right: 0;
    top: 0;
    width: 1px;
    height: 100%;
    background: #f4f4f4;
}
header nav.navbar .navbar-collapse ul.navbar-nav li.mega-menu ul.mega-menu-block li .mega-menu-content .mega-content-block:last-child:before {
    content: none;
}
header nav.navbar .navbar-collapse ul.navbar-nav li.mega-menu ul.mega-menu-block li .mega-menu-content .mega-content-block a {
    display: block;
    padding: 15px 0;
    color: #696969;
}
header nav.navbar .navbar-collapse ul.navbar-nav li.mega-menu ul.mega-menu-block li .mega-menu-content .mega-content-block a:hover {
    color: #a2bd30;
}
header nav.navbar .navbar-collapse ul.navbar-nav li.mega-menu:hover ul.mega-menu-block {
    visibility: visible;
    opacity: 1;
    top: 100px;
}
header .is-sticky {
    background: #fff;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.25);
    z-index: 1000 !important;
    transition: 0.3s ease;
    border-bottom: solid #008a2d;
}
header#header-style02 .is-sticky,
header#header-style02.header-sticky {
    padding-bottom: 0;
}
header#header-style02.header-sticky .is-sticky {
    background: #a2bd30;
}
header#header-style02.header-sticky .is-sticky .container .col-sm-12:first-child {
    visibility: hidden;
    height: 0;
}
header#header-style02.header-sticky .is-sticky nav.navbar .navbar-collapse {
    padding-left: 0;
    padding-right: 0;
}

.login.container {
  max-width: 320px;
}

@keyframes slideInUp {
    from {
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@-webkit-keyframes slideInUp {
    from {
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

input.main-search-input::-webkit-input-placeholder {
    color: #424242;
}

input.main-search-input:-moz-placeholder {
    color: #424242;
    opacity: 1;
}

input.main-search-input::-moz-placeholder {
    color: #424242;
    opacity: 1;
}

input.main-search-input:-ms-input-placeholder {
    color: #424242;
}

#search-close {
    position: absolute;
    top: 25px;
    right: 0px;
    font-size: 30px;
    color: #424242;
    cursor: pointer;
  transition:0.3s ease;
}
#search-close:hover{
  color:#a2bd30;
}
.mobile-links li > a {
    padding: 15px 0 15px 15px;
    line-height: 20px;
    border-bottom: 1px solid #eaeaea;
    color: #6c6c6c;
    display: block;
}

.mobile-links li > a:hover {
    color: #435BE0;
}

header nav.navbar .navbar-cmllapse ul.navbar-nav li.nav-right{
  margin-right:0;
  padding:0;
}


/* Slider Section */
.slider {
    position: relative;
}
.slider .slider-caption {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
            transform: translateY(-50%);
}
.slider .tp-caption .slider-btn-left {
    margin-right: 20px;
}
.slider .slider-bottom-btn {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 50px;
    text-align: center;
}
.slider .slider-bottom-btn a {
    display: inline-block;
}
.slider .slider-bottom-boxes {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
}
.slider .slider-bottom-boxes .about-boxes a {
    display: block;
    background: rgba(60,61,65,0.7);
    padding: 20px;
    color: #fff;
}
.slider .slider-bottom-boxes .about-boxes a:hover {
    background: #3c3d41;
}
.slider .slider-bottom-boxes .about-boxes a ul {
    margin-bottom: 0;
}
.slider .slider-bottom-boxes .about-boxes a ul li {
    display: table-cell;
    vertical-align: top;
    font-size: 24px;
    font-weight: 600;
    font-family: "Roboto Slab", serif;
}
.slider .slider-bottom-boxes .about-boxes a ul li:last-child {
    padding-left: 20px;
}
.slider .slider-bottom-boxes .about-boxes a ul li img {
    margin-top: 10px;
}


/* Blog */
.blog {
    padding: 100px 0;
}
.blog .blog-posts .single-blog {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.13);
}
.blog .blog-posts .single-blog figure {
    position: relative;
    overflow: hidden;
}
.blog .blog-posts .single-blog figure .blog-thumb {
    padding: 5px;
}
.blog .blog-posts .single-blog figure .blog-thumb img {
    width: 100%;
    transition: 0.5s ease;
}
.blog .blog-posts .single-blog figure figcaption {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}
.blog .blog-posts .single-blog figure figcaption p.post-date {
    position: absolute;
    right: 25px;
    top: 25px;
    width: 60px;
    height: 60px;
    background: #a2bd30;
    border-radius: 100px;
    text-align: center;
    font-size: 18px;
    color: #fff;
    font-weight: 400;
    padding-top: 4px;
}
.blog .blog-posts .single-blog figure figcaption a {
    position: absolute;
    left: 0;
    bottom: -5px;
    padding: 5px 10px 10px;
    font-size: 17px;
    font-family: "Roboto Slab", serif;
    background: #a2bd30;
    color: #fff;
}
.blog .blog-posts .single-blog .blog-excerpt {
    padding: 20px 15px;
    overflow: hidden;
    position: relative;
}
.blog .blog-posts .single-blog .blog-excerpt h3 {
    margin: 0 0 20px 0;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 26px;
}
.blog .blog-posts .single-blog .blog-excerpt h3 a {
    color: #323232;
    transition: 0.3s ease;
}
.blog .blog-posts .single-blog .blog-excerpt p + a {
    color: #323232;
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    margin-top: 10px;
    text-transform: uppercase;
}
.blog .blog-posts .single-blog .blog-excerpt p + a i {
    display: inline-block;
    padding-left: 5px;
}
.blog .blog-posts .single-blog:hover figure .blog-thumb img {
    -webkit-transform: scale(1.1);
        -ms-transform: scale(1.1);
            transform: scale(1.1);
}
.blog .blog-posts .single-blog:hover .blog-excerpt h3 a {
    color: #a2bd30;
}
.blog .blog-posts .single-blog:hover .blog-excerpt a {
    color: #a2bd30;
}


/* Footer */
footer {
    padding: 80px 0 0 0;
    background: #232323;
    color: #fff;
    position: relative;
}
footer .widget {
    margin-bottom: 50px;
}
footer .widget .widget-title h2 {
    font-size: 20px;
    color: #a2bd30;
    margin: 5px 0 30px 0;
    font-weight: 600;
    text-transform: uppercase;
}
footer .widget .widget-title img {
    margin-bottom: 15px;
}
footer .widget.about-widget .widget-content p {
    color: #fff;
}
footer .widget.about-widget .widget-content ul {
    margin-top: 25px;
}
footer .widget.about-widget .widget-content ul li {
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid #39534C;
}
footer .widget.about-widget .widget-content ul li svg {
    display: inline-block;
    padding-right: 5px;
    font-size: 16px;
    color: #a2bd30;
}
footer .widget.footer-social .widget-content ul li {
    display: inline-block;
    margin-right: 25px;
}
footer .widget.footer-social .widget-content ul li:last-child {
    margin-right: 0;
}
footer .widget.footer-social .widget-content ul li a {
    color: #fff;
    font-size: 20px;
}
footer .widget.footer-social .widget-content ul li a:hover {
    color: #a2bd30;
}
footer .widget.links .widget-content ul li {
    margin-bottom: 20px;
}
footer .widget.links .widget-content ul li:last-child {
    margin-bottom: 0;
}
footer .widget.links .widget-content ul li a {
    color: #fff;
}
footer .widget.links .widget-content ul li a:hover {
    color: #a2bd30;
}
footer .widget.latest-news .widget-content .news-single {
    margin-bottom: 20px;
    padding-bottom: 15px;
    border-bottom: 1px solid #39534C;
}
footer .widget.latest-news .widget-content .news-single:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border: 0 none;
}
footer .widget.latest-news .widget-content .news-single .news-thumb {
    display: table-cell;
    vertical-align: top;
}
footer .widget.latest-news .widget-content .news-single .news-thumb img {
  max-width: 60px;
}
footer .widget.latest-news .widget-content .news-single .news-excerpt {
    display: table-cell;
    vertical-align: top;
    padding-left: 20px;
}
footer .widget.latest-news .widget-content .news-single .news-excerpt h3 {
    margin: -5px 0 5px 0;
}
footer .widget.latest-news .widget-content .news-single .news-excerpt h3 a {
    font-size: 15px;
    color: #fff;
    font-weight: normal;
    text-transform: none;
}
footer .widget.latest-news .widget-content .news-single .news-excerpt h3 a:hover {
    color: #a2bd30;
}
footer .widget.latest-news .widget-content .news-single .news-excerpt span {
    font-size: 12px;
    color: #6b6b6b;
}
footer .widget.contact-widget .widget-content form {
    position: relative;
}
footer .widget.contact-widget .widget-content form input {
    height: 35px;
    border: 1px solid #39534c;
    display: block;
    width: 100%;
    background: transparent;
    padding: 5px;
    margin-bottom: 15px;
    box-shadow: none;
}
footer .widget.contact-widget .widget-content form textarea {
    border: 1px solid #39534c;
    display: block;
    width: 100%;
    background: transparent;
    padding: 5px;
    margin-bottom: 15px;
    box-shadow: none;
    resize: none;
    height: 100px;
}

/* Footer Bottom */
.footer-bottom {
    background: #2d3e50;
}
.footer-bottom .footer-bottom-inner {
    border-top: 1px solid #39534c;
    padding: 35px 0;
}
.footer-bottom p {
    color: rgba(255, 255, 255, 0.7);
    font-size: 14px;
    font-family: "Roboto Slab", serif;
    margin: 0;
    font-weight: 300;
}
.footer-bottom p#back-top {
    text-align: right;
    margin-bottom: 0;
    position: fixed;
    bottom: 30px;
    right: 50px;
    z-index: 10;
}
.footer-bottom p#back-top a {
    background: #323232;
    color: #fff;
    display: inline-block;
    font-size: 25px;
    height: 35px;
    text-align: center;
    width: 35px;
    padding-top: 3px;
}
.footer-bottom p#back-top a:hover {
    background: #a2bd30;
    border-color: #a2bd30;
    color: #fff;
}
.footer-bottom.footer-bottom-default .footer-bottom-inner {
    border-top: 0;
}
.footer-bottom ul.footer-menu {
    text-align: right;
    margin-bottom: 0;
}
.footer-bottom ul.footer-menu li {
    display: inline-block;
    padding-right: 15px;
}
.footer-bottom ul.footer-menu li:last-child {
    padding-right: 0;
}
.footer-bottom ul.footer-menu li a {
    color: rgba(255, 255, 255, 0.7);
    opacity: .7;
    font-size: 15px;
    font-weight: 300;
}
.footer-bottom ul.footer-menu li a:hover {
    color: #a2bd30;
}


/* ==============================
  Home Page Version 03
================================ */

/* Footer */

.footer-style03 {
    /* background: url(../images/footer-bg.jpg); */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.footer-bottom {
    background: #1b1b1b;
}


/* ==============================
  Home Page Version 06
================================ */

/* Section Header (03) */

.section-header03 h2 {
    margin: 0 0 70px 0;
    padding: 0 0 30px 0;
    position: relative;
    line-height: 30px;
    font-weight: 600;
    text-transform: uppercase;
}
.section-header03 h2:after {
    position: absolute;
    content: "";
    width: 120px;
    height: 1px;
    background: #323232;
    left: 0;
    bottom: 0;
}
.section-header03 h2 span {
    color: #a2bd30;
}

/* ==============================
  Home Page Version 08
================================ */
/* Slider Bottom */

.slider-bottom {
    background: #F6F6F6;
    padding: 40px 0;
}

/* Courses */

.courses08 {
    padding: 100px 0 70px 0;
}
.courses08 .section-header04 {
    margin-bottom: 20px;
}
.courses08 .course08-carousel .owl-stage-outer {
    padding: 30px 0;
}
.courses08 .course08-carousel .carousel-item {
    padding-bottom: 20px;
}
.courses08 .course08-carousel .carousel-item .course-single {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.13);
    margin: 20px;
    transition: .3s ease;
}
.courses08 .course08-carousel .carousel-item .course-single:last-child {
    margin-bottom: 0;
}
.courses08 .course08-carousel .carousel-item .course-single:hover .course-info h2 {
    color: #a2bd30;
}
.courses08 .course08-carousel .carousel-item .course-single:hover .course-image figure figcaption {
    visibility: visible;
    opacity: 1;
}
.courses08 .course08-carousel .carousel-item .course-single .course-info {
    padding: 30px 0 15px 20px;
}
.courses08 .course08-carousel .carousel-item .course-single .course-info h3 {
    font-weight: 600;
    margin: 0 0 10px 0;
    transition: .3s ease;
    text-transform: uppercase;
}
.courses08 .course08-carousel .carousel-item .course-single .course-info ul.course-bottom {
    border-top: 1px solid #d5d5d5;
    padding-top: 15px;
}
.courses08 .course08-carousel .carousel-item .course-single .course-info ul.course-bottom li {
    display: inline-block;
    font-size: 15px;
    color: #727272;
}
.courses08 .course08-carousel .carousel-item .course-single .course-info ul.course-bottom li:last-child {
    float: right;
    font-size: 15px;
    color: #a2bd30;
}
.courses08 .course08-carousel .carousel-item .course-single .course-image figure {
    position: relative;
}
.courses08 .course08-carousel .carousel-item .course-single .course-image figure figcaption {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    text-align: center;
    transition: 0.3s ease;
    visibility: hidden;
    opacity: 0;
}
.courses08 .course08-carousel .carousel-item .course-single .course-image figure figcaption div {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
            transform: translateY(-50%);
    width: 100%;
}
.courses08 .course08-carousel .carousel-item .course-single .course-image figure figcaption div a {
    border-radius: 0;
}
.courses08 .owl-nav {
    left: 0;
    padding-right: 30px;
    position: absolute;
    text-align: right;
    top: -62px;
    width: 100%;
}
/* .owl-carousel .owl-nav button.owl-prev, .owl-carousel .owl-nav button.owl-next, .owl-carousel button.owl-dot, */
.courses08 .owl-carousel .owl-nav .owl-prev svg,
.courses08 .owl-carousel .owl-nav .owl-next svg {
    width: 30px;
    height: 30px;
}
.courses08 .owl-carousel .owl-nav .owl-prev,
.courses08 .owl-carousel .owl-nav .owl-next {
    display: inline-block;
    text-align: center;
    font-size: 15px;
    border: 1px solid #323232;
    color: #323232;
    padding-top: 5px;
    transition: 0.3s ease;
}
.courses08 .owl-nav .owl-prev:hover,
.courses08 .owl-nav .owl-next:hover {
    color: #a2bd30;
    border-color: #a2bd30;
    cursor: pointer;
}
.courses08 .owl-nav .owl-prev {
    margin-right: 10px;
}


/* ==============================
  Home Page Version 09
================================ */

/* Section Header */

.section-header-d {
    margin-bottom: 40px;
}
.section-header-d h2 {
    line-height: 30px;
    margin-bottom: 12px;
}
.section-header-d h2 span {
    color: #a2bd30;
}


/* ==============================
  Home Page Version 10
================================ */
/* About */

.about-ds {
    padding: 100px 0;
}
.about-ds .section-header03 h2 {
    margin-bottom: 40px;
}
.about-ds .about-ds-content p {
    margin-bottom: 30px;
}
.about-ds .about-ds-content a.rm-btn:hover {
    border-color: #a2bd30;
    color: #a2bd30;
}



/* ==============================
  Coming Soon
================================ */

html,
body {
    height: 100%;
    margin: 0;
}

/* ===============================
  Blog One Style
================================ */

.page-header {
    padding: 80px 0;
    position: relative;
    margin: 0;
}
.page-header:before {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
}
.page-header h1 {
    font-weight: 600;
    font-size: 50px;
    color: #fff;
    margin: 0 0 20px 0;
    text-transform: uppercase;
}
.page-header ul li {
    display: inline-block;
    margin-right: 20px;
    position: relative;
    font-size: 16px;
}
.page-header ul li:last-child {
    margin-right: 0;
}
.page-header ul li:last-child:before {
    content: none;
}
.page-header ul li:before {
    position: absolute;
    right: -15px;
    top: 0;
    content: "/";
    color: #fff;
}
.page-header ul li a {
    color: #fff;
    font-size: 16px;
}
.page-header ul li.active {
    color: rgba(255, 255, 255, 0.7);
}


/* Blog Page Styles */

.blog-wrapper {
    padding: 100px 0;
}
.blog-wrapper .blog-pagination ul {
    border-bottom: 1px solid rgba(0, 0, 0, 0.04);
    border-top: 1px solid rgba(0, 0, 0, 0.04);
    margin-bottom: 0;
    padding: 10px 0;
    text-align: center;
}
.blog-wrapper .blog-pagination ul li {
    display: inline-block;
}
.blog-wrapper .blog-pagination ul li a {
    background: none;
  border: none;
    font-weight: 500;
    display: block;
    padding: 10px 15px;
    color: #727272;
  transition: 0.3s ease;
  margin: 2px;
}
.blog-wrapper .blog-pagination ul li a:hover {
    background: #a2bd30;
    color: #fff;
}
.blog-wrapper .blog-pagination ul li.active a {
    background: #a2bd30;
    color: #fff;
}
.blog-wrapper .blog-sidebar .sidebar-widget {
    margin-bottom: 50px;
}
.blog-wrapper .blog-sidebar .sidebar-widget:last-child {
    margin-bottom: 0;
}
.blog-wrapper .blog-sidebar .sidebar-widget h3 {
    margin: 0 0 20px 0;
    padding-bottom: 10px;
    position: relative;
    font-size: 20px;
}
.blog-wrapper .blog-sidebar .sidebar-widget h3:before {
    position: absolute;
    left: 0;
    bottom: 0;
    content: "";
    width: 60px;
    height: 1px;
    background: #a2bd30;
}
.blog-wrapper .blog-sidebar .sidebar-widget .widget-content.search-widget form {
    position: relative;
}
.blog-wrapper .blog-sidebar .sidebar-widget .widget-content.search-widget form button {
    position: absolute;
    padding: 0;
    top: 0;
    right: 0;
    width: 50px;
    height: 45px;
    border: 1px solid #a2bd30;
    background: #a2bd30;
    color: #fff;
}
.blog-wrapper .blog-sidebar .sidebar-widget .widget-content.search-widget form button:hover {
    background: none;
    color: #a2bd30;
}
.blog-wrapper .blog-sidebar .sidebar-widget .widget-content.latest-news .news-single {
    margin-bottom: 20px;
    padding-bottom: 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.13);
}
.blog-wrapper .blog-sidebar .sidebar-widget .widget-content.latest-news .news-single:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border: 0 none;
}
.blog-wrapper .blog-sidebar .sidebar-widget .widget-content.latest-news .news-single .news-thumb {
    display: table-cell;
    vertical-align: top;
}
.blog-wrapper .blog-sidebar .sidebar-widget .widget-content.latest-news .news-single .news-excerpt {
    display: table-cell;
    vertical-align: top;
    padding-left: 20px;
}
.blog-wrapper .blog-sidebar .sidebar-widget .widget-content.latest-news .news-single .news-excerpt h4 {
    margin: -5px 0 5px 0;
}
.blog-wrapper .blog-sidebar .sidebar-widget .widget-content.latest-news .news-single .news-excerpt h4 a {
    font-size: 15px;
    color: #727272;
    font-weight: normal;
    text-transform: none;
}
.blog-wrapper .blog-sidebar .sidebar-widget .widget-content.latest-news .news-single .news-excerpt h4 a:hover {
    color: #a2bd30;
}
.blog-wrapper .blog-sidebar .sidebar-widget .widget-content.latest-news .news-single .news-excerpt span {
    font-size: 12px;
    color: #6b6b6b;
}
.blog-wrapper .blog-sidebar .sidebar-widget .widget-content.category-widget ul {
    margin-bottom: 0;
}
.blog-wrapper .blog-sidebar .sidebar-widget .widget-content.category-widget ul li {
    padding-bottom: 15px;
}
.blog-wrapper .blog-sidebar .sidebar-widget .widget-content.category-widget ul li:last-child {
    padding-bottom: 0;
}
.blog-wrapper .blog-sidebar .sidebar-widget .widget-content.category-widget ul li a {
    color: #727272;
}
.blog-wrapper .blog-sidebar .sidebar-widget .widget-content.category-widget ul li a:hover {
    color: #a2bd30;
}
.blog-wrapper .blog-sidebar .sidebar-widget .widget-content.tags-widget ul {
    margin-bottom: 0;
}
.blog-wrapper .blog-sidebar .sidebar-widget .widget-content.tags-widget ul li {
    display: inline-block;
    padding-right: 10px;
    padding-bottom: 10px;
}
.blog-wrapper .blog-sidebar .sidebar-widget .widget-content.tags-widget ul li:last-child {
    padding-right: 0;
}
.blog-wrapper .blog-sidebar .sidebar-widget .widget-content.tags-widget ul li a {
    display: block;
    padding: 10px 15px;
    border: 1px solid rgba(0, 0, 0, 0.13);
    color: #727272;
}
.blog-wrapper .blog-sidebar .sidebar-widget .widget-content.tags-widget ul li a:hover {
    border-color: #a2bd30;
    color: #a2bd30;
}


/* ===============================
  Blog Four Style
================================ */

.blog-wrapper.blog .blog-four-content .blog-posts .single-blog {
    margin-bottom: 50px;
}


/* ===============================
  Blog Details Style
================================ */

.blog-wrapper .blog-details-wrapper .blog-image img {
    width: 100%;
}
.blog-wrapper .blog-details-wrapper .blog-meta {
    margin: 20px 0 25px 0;
}
.blog-wrapper .blog-details-wrapper .blog-meta h2 {
    margin: 0;
    font-size: 30px;
    text-transform: uppercase;
}
.blog-wrapper .blog-details-wrapper .blog-meta ul {
    margin: 15px 0 0 0;
}
.blog-wrapper .blog-details-wrapper .blog-meta ul li {
    display: inline-block;
    margin-right: 20px;
}
.blog-wrapper .blog-details-wrapper .blog-meta ul li:last-child {
    margin-right: 0;
}
.blog-wrapper .blog-details-wrapper .blog-meta ul svg {
    display: inline-block;
    color: #a2bd30;
    margin-right: 4px;
}
.blog-wrapper .blog-details-wrapper .blog-meta ul li a {
    color: inherit;
}
.blog-wrapper .blog-details-wrapper .blog-meta ul li a:hover {
    color: #a2bd30;
}
.blog-wrapper .blog-details-wrapper .blog-meta ul li span:not(:last-child):after {
  content: ' / ';
}
.blog-wrapper .blog-details-wrapper .blog-content blockquote {
    margin: 40px 0;
    background: rgba(0, 0, 0, 0.04);
    padding: 15px;
    border-left: 2px solid #a2bd30;
    font-style: italic;
}
.blog-wrapper .blog-details-wrapper .blog-share ul {
    margin: 60px 0 0 0;
    padding: 15px 0;
    border-top: 1px solid rgba(0, 0, 0, 0.04);
    border-bottom: 1px solid rgba(0, 0, 0, 0.04);
}
.blog-wrapper .blog-details-wrapper .blog-share ul li {
    display: inline-block;
    padding-right: 10px;
}
.blog-wrapper .blog-details-wrapper .blog-share ul li:last-child {
    padding-right: 0;
}
.blog-wrapper .blog-details-wrapper .blog-share ul li:first-child {
    font-weight: 500;
}
.blog-wrapper .blog-details-wrapper .blog-share ul li a {
    color: inherit;
}
.blog-wrapper .blog-details-wrapper .blog-share ul li a:hover {
    color: #a2bd30;
}
.blog-wrapper .blog-details-wrapper .blog-comment {
    margin: 60px 0 0 0;
}
.blog-wrapper .blog-details-wrapper .blog-comment h2 {
    font-size: 25px;
    margin: 0 0 40px 0;
    text-transform: uppercase;
}
.blog-wrapper .blog-details-wrapper .blog-comment ul.comment-list li {
    margin-bottom: 50px;
}
.blog-wrapper .blog-details-wrapper .blog-comment ul.comment-list li .comment-author {
    display: table-cell;
    vertical-align: top;
}
.blog-wrapper .blog-details-wrapper .blog-comment ul.comment-list li .comment-details {
    display: table-cell;
    vertical-align: top;
    padding-left: 30px;
}
.blog-wrapper .blog-details-wrapper .blog-comment ul.comment-list li .comment-details h3 {
    margin: 0 0 10px 0;
    color: #a2bd30;
}
.blog-wrapper .blog-details-wrapper .blog-comment ul.comment-list li .comment-details p {
    margin: 10px 0;
}
.blog-wrapper .blog-details-wrapper .blog-comment ul.comment-list li .comment-details a {
    color: inherit;
    font-weight: 500;
}
.blog-wrapper .blog-details-wrapper .blog-comment ul.comment-list li .comment-details a:hover {
    color: #a2bd30;
}
.blog-wrapper .blog-details-wrapper .blog-comment ul.comment-list li ul.children {
    margin-left: 50px;
}
.blog-wrapper .blog-details-wrapper .blog-comment ul.comment-list li ul.children li {
    margin: 50px 0 0 0;
}
.blog-wrapper .blog-details-wrapper .blog-comment ul.comment-list li ul.children li:last-child {
    margin-bottom: 0;
}


/* ================================
  Contact One Style
================================== */

.contact-wrapper {
    padding: 100px 0;
}
.contact-wrapper .contact-map h2 {
    font-size: 25px;
    font-weight: 600;
    text-transform: uppercase;
    margin: 0 0 50px 0;
    line-height: 22px;
}
.contact-wrapper .contact-boxes {
    margin-top: 100px;
    text-align: center;
    font-size: 30px;
    color: #a2bd30;
}

/* ================================
  Contact Two Style
================================== */

.contact-wrapper .contact-two-top h3 {
    font-size: 22px;
    margin: 0 0 40px 0;
}
.contact-wrapper .contact-two-top .contact-info .contact-info-box {
    border-bottom: 1px solid #e8e8e8;
    padding-bottom: 15px;
    margin-bottom: 20px;
}
.contact-wrapper .contact-two-top .contact-info .contact-info-box .icon,
.contact-wrapper .contact-two-top .contact-info .contact-info-box .cinfo {
    display: table-cell;
    vertical-align: top;
}
.contact-wrapper .contact-two-top .contact-info .contact-info-box .icon {
    width: 60px;
}
.contact-wrapper .contact-two-top .contact-info .contact-info-box .icon svg {
    font-size: 40px;
    color: #a2bd30;
}
.contact-wrapper .contact-two-top .contact-info .contact-info-box .cinfo h4 {
    margin: 0 0 5px 0;
    text-transform: uppercase;
}
.contact-wrapper .contact-two-top .contact-info .contact-info-box .cinfo p {
    margin-bottom: 0;
}
.contact-wrapper .contact-two-bottom {
    padding-top: 60px;
    margin-top: 40px;
}
.contact-wrapper .contact-two-bottom h3 {
    font-size: 22px;
    margin: 0 0 40px 0;
}
.contact-wrapper .contact-two-bottom form .form-group {
    margin-bottom: 0;
}
.contact-wrapper .contact-map iframe {
  pointer-events: none;
  width: 100%;
  border: 0 none;
}
