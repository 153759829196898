.votings {
  display: flex;
}

.voting {
  border: 2px black solid;
  text-decoration: none;
  display: block;
  padding: 1rem;
}

.voting p {
  margin-bottom: 1rem !important;
}

.voting:hover {
  border: 2px #a2bd30 solid;
}

.voting:hover h3 {
  text-decoration: underline;
  color: #a2bd30;
}
