@media only screen and (max-width: 991px) {
    #responsive-navbar-nav {
        width: 100%;
        padding-right: 0;
    }
    header nav.navbar .navbar-collapse ul.navbar-nav li a::after {
        content: none;
    }
    .aavbar- {
        float: none;
    }
    .navbar-toggler {
        display: block;
        margin: 30px 0;
    }
    .navbar-collapse {
        border-top: 1px solid transparent;
        box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
    }
    .navbar-fixed-top {
        top: 0;
        border-width: 0 0 1px;
    }
    .navbar-nav {
        float: none!important;
        margin-top: 7.5px;
    }
    .navbar-nav>li {
        float: none;
    }
    .navbar-nav>li>a {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .collapse.in {
        display: block !important;
    }
    .navbar-icon-bars {
        color: #80CD36;
        font-size: larger;
    }
    .navbar-toggler:focus {
      box-shadow: none;
    }
    header nav.navbar .navbar-collapse ul.navbar-nav {
        float: none !important;
    }
    header nav.navbar .navbar-collapse ul.navbar-nav li {
        margin: 0;
        padding: 0;
        border-bottom: 1px solid #eee;
    }
    header nav.navbar .navbar-collapse ul.navbar-nav li ul.dropdown-menu {
        opacity: 1;
        visibility: visible;
        display: none;
        margin-top: 0;
    }
    header nav.navbar .navbar-collapse ul.navbar-nav li.open ul.dropdown-menu {
        display: block;
    }
    header nav.navbar .navbar-collapse ul.navbar-nav li.dropdown i {
        padding: 18px 0;
        position: absolute;
        right: 0;
        top: 0;
    }
    header nav.navbar .navbar-collapse ul.navbar-nav li.mega-menu {
        position: relative;
    }
    header nav.navbar .navbar-collapse ul.navbar-nav li a {
        display: block;
        padding: 15px 0;
    }
    div.header-top-right {
        justify-content: center;
    }
    a.apply-btn,
    button.apply-btn {
        padding: 10px 20px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    /* ---------------------------------------------
    Main Demo
  ----------------------------------------------- */

    .blog-main .course08-carousel .carousel-item .course-single .course-info {
        padding-right: 15px;
    }
    .footer-bottom .footer-bottom-inner,
    .footer-bottom .footer-bottom-inner ul.footer-menu {
        text-align: center;
    }
    .footer-bottom .footer-bottom-inner ul.footer-menu {
        margin-top: 15px;
    }
    /* --------------------------------------------------------------------------
    Template Version 2
  ----------------------------------------------------------------------------- */

    nav.navbar .collapse.in {
        margin: 0;
    }
    nav.navbar .collapse.in ul.navbar-nav {
        margin: 0;
    }
    header nav.navbar .navbar-collapse ul.navbar-nav li:last-child {
        border-bottom: 0 none;
    }

    /* --------------------------------------------------------------------------
    Kindergarten Version 02
  ----------------------------------------------------------------------------- */

    .section-header03 h2 {
        line-height: 45px;
    }
}
@media only screen and (max-width: 767px) {
    .blog-sidebar {
        margin-top: 50px;
    }
    .footer-bottom p {
        text-align: center;
    }
    .footer-bottom ul.footer-menu {
        margin-top: 15px;
        text-align: center;
    }
    .contact-two-top .contact-info {
        margin-top: 50px;
    }
    .blog-posts .single-blog .blog-thumb img {
        width: 100%;
    }
    .nav ul li a figure img {
        width: 100%;
    }
    /* --------------------------------------------------------------
    Main Demo
  --------------------------------------------------------------- */

    .blog-main .course08-carousel .carousel-item .course-single .course-info {
        padding-right: 15px;
    }
    .section-header h2 {
        font-size: 45px;
    }
    .section-header span::before,
    .section-header span::after {
        width: 80px;
    }
    .blog .blog-posts .single-blog {
        margin-bottom: 30px;
    }

    /* --------------------------------------------------------
  Template Version 03
  ---------------------------------------------------------- */

    .footer-bottom p#back-top {
        right: 15px;
    }
    /* --------------------------------------------------------------------------
    Template Version 6
  ----------------------------------------------------------------------------- */

    .section-header03 h2 {
        font-size: 30px;
    }
    /* --------------------------------------------------------------------------
    Template Version 7
  ----------------------------------------------------------------------------- */

    .section-header04 h2 {
        font-size: 30px;
    }
    /* --------------------------------------------------------------------------
    Template Version 10
  ----------------------------------------------------------------------------- */

    .about-ds .about-ds-content a.rm-btn {
        margin-bottom: 50px;
    }

    /* --------------------------------------------------------
    Blog 01
  --------------------------------------------------------- */

    .blog-pagination {
        margin-bottom: 100px;
    }
  .tp-caption.sfr.font-extra-bold.tp-resizeme.letter-space-4, .tp-caption.font-lora.sfb.tp-resizeme.letter-space-5, .tp-caption.lfb.tp-resizeme {
    margin-left: 15px !important;
  }
  .slider .slider-bottom-boxes {
    position: static;
  }
  .slider .slider-bottom-blocks {
    bottom: 0;
    position: static;
  }
}
