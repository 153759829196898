/* Accordion */

.accordion {
  --bs-accordion-btn-focus-box-shadow: none;
}

.accordion-item {
  text-shadow: none;
  box-shadow: none;
  background-color: transparent;
  outline: none;
  border-color: rgba(0,0,0,.125);
}

.accordion-button::focus {
  text-shadow: none;
  box-shadow: none;
  background-color: transparent;
  outline: none;
  border-color: rgba(0,0,0,.125);
}

.accordion-header button {
  text-transform: uppercase;
  color: white;
  background-color: #a2bd30;
  box-shadow: none;
}

.accordion-header button::active {
  text-transform: uppercase;
  color: white;
  background-color: #a2bd30;
}

.accordion-button:not(.collapsed) {
  text-transform: uppercase;
  color: white;
  background-color: #a2bd30;
}

.accordion-button:not(.collapsed)::after {
  color: white;
  font-weight: bold;
  font-size: 18px;
}
